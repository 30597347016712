import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ba27a04 = () => interopDefault(import('../pages/theme-0/detail.vue' /* webpackChunkName: "pages/theme-0/detail" */))
const _6f78ce40 = () => interopDefault(import('../pages/theme-0/favorite-games/index.vue' /* webpackChunkName: "pages/theme-0/favorite-games/index" */))
const _2f32a928 = () => interopDefault(import('../pages/theme-0/forgot-password.vue' /* webpackChunkName: "pages/theme-0/forgot-password" */))
const _5308e927 = () => interopDefault(import('../pages/theme-0/games/index.vue' /* webpackChunkName: "pages/theme-0/games/index" */))
const _4f22564c = () => interopDefault(import('../pages/theme-0/login.vue' /* webpackChunkName: "pages/theme-0/login" */))
const _46a16ef0 = () => interopDefault(import('../pages/theme-0/no-support.vue' /* webpackChunkName: "pages/theme-0/no-support" */))
const _56dc4deb = () => interopDefault(import('../pages/theme-0/not-found.vue' /* webpackChunkName: "pages/theme-0/not-found" */))
const _2fb83b4e = () => interopDefault(import('../pages/theme-0/play-game.vue' /* webpackChunkName: "pages/theme-0/play-game" */))
const _c8e7f9ee = () => interopDefault(import('../pages/theme-0/promotion/index.vue' /* webpackChunkName: "pages/theme-0/promotion/index" */))
const _a9ace1e0 = () => interopDefault(import('../pages/theme-0/register.vue' /* webpackChunkName: "pages/theme-0/register" */))
const _e6d4e9ae = () => interopDefault(import('../pages/theme-0/player/deposits/index.vue' /* webpackChunkName: "pages/theme-0/player/deposits/index" */))
const _4fd2d620 = () => interopDefault(import('../pages/theme-0/player/messages/index.vue' /* webpackChunkName: "pages/theme-0/player/messages/index" */))
const _22451128 = () => interopDefault(import('../pages/theme-0/player/profile.vue' /* webpackChunkName: "pages/theme-0/player/profile" */))
const _2e1b5ace = () => interopDefault(import('../pages/theme-0/player/wallets.vue' /* webpackChunkName: "pages/theme-0/player/wallets" */))
const _9966bec0 = () => interopDefault(import('../pages/theme-0/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-0/player/withdrawals/index" */))
const _52a9e7fa = () => interopDefault(import('../pages/theme-0/providers/games/index.vue' /* webpackChunkName: "pages/theme-0/providers/games/index" */))
const _f50a9996 = () => interopDefault(import('../pages/theme-0/index.vue' /* webpackChunkName: "pages/theme-0/index" */))
const _14b4be3c = () => interopDefault(import('../pages/theme-0/providers/games/_id.vue' /* webpackChunkName: "pages/theme-0/providers/games/_id" */))
const _3067945a = () => interopDefault(import('../pages/theme-0/Information/_id.vue' /* webpackChunkName: "pages/theme-0/Information/_id" */))
const _073efea0 = () => interopDefault(import('../pages/theme-0/providers/_id.vue' /* webpackChunkName: "pages/theme-0/providers/_id" */))
const _3a7646a7 = () => interopDefault(import('../pages/theme-0/_type/_id.vue' /* webpackChunkName: "pages/theme-0/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/detail",
    component: _7ba27a04,
    name: "detail"
  }, {
    path: "/favorite-games",
    component: _6f78ce40,
    name: "favorite-games"
  }, {
    path: "/forgot-password",
    component: _2f32a928,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _5308e927,
    name: "games"
  }, {
    path: "/login",
    component: _4f22564c,
    name: "login"
  }, {
    path: "/no-support",
    component: _46a16ef0,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _56dc4deb,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _2fb83b4e,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _c8e7f9ee,
    name: "promotion"
  }, {
    path: "/register",
    component: _a9ace1e0,
    name: "register"
  }, {
    path: "/player/deposits",
    component: _e6d4e9ae,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _4fd2d620,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _22451128,
    name: "player-profile"
  }, {
    path: "/player/wallets",
    component: _2e1b5ace,
    name: "player-wallets"
  }, {
    path: "/player/withdrawals",
    component: _9966bec0,
    name: "player-withdrawals"
  }, {
    path: "/providers/games",
    component: _52a9e7fa,
    name: "providers-games"
  }, {
    path: "/",
    component: _f50a9996,
    name: "index"
  }, {
    path: "/providers/games/:id",
    component: _14b4be3c,
    name: "providers-games-id"
  }, {
    path: "/Information/:id?",
    component: _3067945a,
    name: "Information-id"
  }, {
    path: "/providers/:id?",
    component: _073efea0,
    name: "providers-id"
  }, {
    path: "/:type/:id?",
    component: _3a7646a7,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
